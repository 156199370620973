import styled from "styled-components";
import tw from "twin.macro";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css } from "styled-components/macro";
import { Link } from "components/link";
import { ProductType } from "components/products/ProductType";

const Heading = tw.p`font-normal text-2xl md:text-2xl lg:text-3xl xl:text-4xl text-gray-900 leading-tight`;
const BaseButton = tw.button`w-full inline-block  font-bold mr-2 rounded-full py-2 sm:w-40 leading-none focus:outline-none transition duration-300`;

const Button = styled(BaseButton)((props: { disabled: boolean }) => [
  props.disabled
    ? tw`bg-gray-500 text-gray-100 cursor-default`
    : tw`bg-primary-500 text-gray-100 hover:bg-primary-900`,
]);

const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

type Item = {
  title: string;
  description: string;
  label: string;
  disabled: boolean;
  onClick?: () => void;
  type?: ProductType;
};

const items: Item[] = [
  {
    title: "Talk to us",
    description:
      "Schedule a 15, 30, or 60 minutes call to talk about your most pressing problem.",
    label: "Schedule",
    disabled: false,
    onClick: () => (window.location.href = "/free-call"),
    type: "freeCall",
  },
  {
    title: "Startup Checklist",
    description:
      "DO's and DON'Ts relevant to the stage of your startup evolution. Please calculate your Startup IQ Score first.",
    label: "Your Checklist",
    disabled: true,
  },
  {
    title: "Startup Canvas",
    description:
      "A custom Startup Canvas to organize your areas of focus. Please calculate your Startup IQ Score first.",
    label: "Your Canvas",
    disabled: true,
  },
  {
    title: "Ask a Question",
    description:
      "You have a lot to process right now. Maybe you have questions. We are here to answer them, so you continue with confidence.",
    label: "Questions",
    disabled: false,
    onClick: () => (window.location.href = "/questions"),
    type: "question",
  },
  {
    title: "Build a Pitch Deck",
    description:
      "If you want to jump straight to the Pitch Deck, we will help you create the content for 10-15 essential slides that are part of any application for startup funding.",
    label: "Pitch Deck",
    disabled: false,
    onClick: () => (window.location.href = "/pitch-deck"),
    type: "pitchDeck",
  },
  {
    title: "Find Funding",
    description:
      "If you have your pitch ready, we will help you identify funding source, the right size funding, and the best way to pitch your deck to potential investors.",
    label: "Funding",
    disabled: false,
    onClick: () => (window.location.href = "/funding"),
    type: "funding",
  },
];

type ProductGalleryProps = {
  types?: ProductType[];
};

export const ProductGallery = ({ types }: ProductGalleryProps) => {
  return (
    <div tw="w-full">
      <Heading tw="text-primary-500 text-center">
        Start with a Plan that Works for You
      </Heading>
      <div tw="text-center w-full">
        <Paragraph>
          If you don't find what works for you,{" "}
          <Link href="mailto:contact@startup-iq.com">email us</Link>.{" "}
        </Paragraph>
      </div>

      <div tw="flex flex-wrap">
        {items
          .filter(
            (item) =>
              types === undefined || (item.type && types.includes(item.type))
          )
          .map((item, i) => (
            <div key={i} tw="w-full md:w-1/3">
              <div tw="border-secondary-200 border-[1px] rounded-md text-center mx-2 mb-4 pb-4">
                <div tw="bg-primary-500 w-full rounded-sm text-white text-center text-xl font-bold p-4 group-hover:bg-secondary-500 group-active:bg-secondary-700">
                  {item.title}
                </div>
                <div tw="p-4">{item.description}</div>
                <div tw="px-4 sm:px-0">
                  <Button disabled={item.disabled} onClick={item.onClick}>
                    {item.label}
                  </Button>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

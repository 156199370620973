import React, { useCallback, useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import GoogleIcon from "images/icons/google.png";
import LinkedInIcon from "images/icons/linkedin.png";
import {
  getAuth,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  updateProfile,
  sendPasswordResetEmail,
} from "firebase/auth";
import { Alert } from "reactstrap";
import * as EmailValidator from "email-validator";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import { API_URL } from "store/config";
import { LINKEDIN_PROFILE } from "constants/localStorage";
import { FadeIn } from "components/misc/animation";

const TextContent = tw.div`text-center md:text-left`;

const Heading = tw.p`font-normal text-2xl md:text-2xl lg:text-3xl xl:text-4xl text-gray-900 leading-tight`;

const Form = tw.div`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
const Sub = styled.div`
  ${tw`relative flex flex-row items-center mt-6`}

  img {
    width: 32px;
    height: 32px;
    margin: 0 6px;
    cursor: pointer;
  }

  &.forgot-password-wrapper {
  }
  .forgot-password {
    cursor: pointer;
    text-decoration: underline;
  }

  &.mode-switcher-wrapper {
  }
  .mode-switcher {
    ${tw`ml-2 cursor-pointer`}
    text-decoration: underline;
  }
`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;

enum SignUpFormMode {
  SIGN_UP,
  SIGN_IN,
}

export type SignUpFormProps = {
  onComplete: () => void;
  showHeading?: boolean;
  showModeSwitcher?: boolean;
  showOtherMethods?: boolean;
  otherMethodsPrefix?: string;
  otherMethodsSuffix?: string;
  showForm?: boolean;
};

export function SignUpFormComponent({
  onComplete,
  showForm = true,
  showModeSwitcher = true,
  showOtherMethods = true,
  otherMethodsPrefix,
  otherMethodsSuffix,
}: SignUpFormProps) {
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [retypePassword, setRetypePassword] = useState<string>("");
  const [disableSubmitButton, setDisableSubmitButton] =
    useState<boolean>(false);
  const [mode, setMode] = useState<SignUpFormMode>(SignUpFormMode.SIGN_UP);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const signUpWithEmailAndPassword = useCallback(() => {
    if (password !== retypePassword) {
      setErrorMessage("Passwords don't match");
    } else if (!EmailValidator.validate(emailAddress)) {
      setErrorMessage(`Invalid email address`);
    } else if (!name.trim()) {
      setErrorMessage(`Please provide your name`);
    } else {
      setDisableSubmitButton(true);
      const auth = getAuth();
      createUserWithEmailAndPassword(auth, emailAddress, password)
        .then((result) => {
          // const user = result.user;
          updateProfile(result.user, {
            displayName: name.trim(),
          })
            .then(() => {
              onComplete();
            })
            .catch(() => {
              onComplete();
            });
        })
        .catch((error) => {
          if (error.code === "auth/email-already-in-use") {
            setErrorMessage("This email address is already being used");
          } else {
            setErrorMessage("Failed to create new account");
          }
        });
      setDisableSubmitButton(false);
    }
  }, [emailAddress, name, password, retypePassword]);

  const { linkedInLogin: signInWithLinkedIn } = useLinkedIn({
    clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID ?? "",
    scope: "r_emailaddress r_liteprofile",
    redirectUri: `${window.location.origin}/linkedin`,
    onSuccess: async (code) => {
      const result = await fetch(
        `${API_URL}/users/auth/linkedin?code=${code}&redirectUri=${encodeURIComponent(
          `${window.location.origin}/linkedin`
        )}`,
        {
          method: "POST",
        }
      );

      if (result.status === 200) {
        const data = await result.json();

        window.localStorage.setItem(LINKEDIN_PROFILE, JSON.stringify(data));
        onComplete();
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const signInWithEmailAndPasswordAuth = useCallback(() => {
    if (!EmailValidator.validate(emailAddress)) {
      setErrorMessage(`Invalid email address`);
    } else {
      const auth = getAuth();
      setDisableSubmitButton(true);
      signInWithEmailAndPassword(auth, emailAddress, password)
        .then(() => {
          onComplete();
        })
        .catch((error) => {
          if (error.code === "auth/wrong-password") {
            setErrorMessage(`Wrong password`);
          } else {
            setErrorMessage(`Failed to sign in`);
          }
        });
      setDisableSubmitButton(false);
    }
  }, [emailAddress, password]);

  const signInWithGoogle = useCallback(() => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then((result) => {
        // const user = result.user;
        onComplete();
      })
      .catch((error) => {
        setErrorMessage(`Failed to sign in with Google`);
      });
  }, []);

  const forgotPassword = useCallback(() => {
    if (!EmailValidator.validate(emailAddress)) {
      setErrorMessage(`Invalid email address`);
    } else {
      const auth = getAuth();
      sendPasswordResetEmail(auth, emailAddress)
        .then(() => {
          console.log("password reset email sent");
          setSuccessMessage(`Password reset email is sent to ${emailAddress}`);
        })
        .catch((error) => {
          if (error.code === "auth/user-not-found") {
            setErrorMessage(`User not found`);
          } else {
            setErrorMessage(`Failed to reset password`);
          }
        });
    }
  }, [emailAddress]);

  return (
    <Form>
      {showForm && (
        <>
          <Input
            type="email"
            name="email"
            placeholder="Your Email Address"
            value={emailAddress}
            onChange={(event) => setEmailAddress(event.target.value)}
          />
          {mode === SignUpFormMode.SIGN_UP && (
            <Input
              type="text"
              name="name"
              placeholder="Your Name"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          )}
          <Input
            type="password"
            name="name"
            placeholder="Password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          {mode === SignUpFormMode.SIGN_UP && (
            <Input
              type="password"
              name="name"
              placeholder="Re-type Password"
              value={retypePassword}
              onChange={(event) => setRetypePassword(event.target.value)}
            />
          )}
          {mode === SignUpFormMode.SIGN_UP ? (
            <SubmitButton
              type="submit"
              onClick={signUpWithEmailAndPassword}
              disabled={disableSubmitButton}
            >
              Create Account
            </SubmitButton>
          ) : (
            <SubmitButton
              type="submit"
              onClick={signInWithEmailAndPasswordAuth}
              disabled={disableSubmitButton}
            >
              Sign In
            </SubmitButton>
          )}
        </>
      )}
      {errorMessage && (
        <Alert color={"danger"} className={"mt-4 mb-0"}>
          {errorMessage}
        </Alert>
      )}
      {successMessage && (
        <Alert color={"success"} className={"mt-4"}>
          {successMessage}
        </Alert>
      )}
      {showOtherMethods && (
        <Sub className={"other-methods"}>
          {otherMethodsPrefix ??
            (mode === SignUpFormMode.SIGN_UP
              ? "Or sign up with"
              : "Or sign in with")}
          <img src={GoogleIcon} alt="google" onClick={signInWithGoogle} />
          <img src={LinkedInIcon} alt="google" onClick={signInWithLinkedIn} />
          {otherMethodsSuffix ?? ""}
        </Sub>
      )}
      {mode === SignUpFormMode.SIGN_IN && (
        <Sub className={"forgot-password-wrapper"}>
          <span className={"forgot-password"} onClick={forgotPassword}>
            Forgot Password?
          </span>
        </Sub>
      )}
      {showModeSwitcher && (
        <Sub className={"mode-switcher-wrapper"}>
          {mode === SignUpFormMode.SIGN_UP ? (
            <>
              Already have an account?{" "}
              <span
                className={"mode-switcher"}
                onClick={() => setMode(SignUpFormMode.SIGN_IN)}
              >
                Sign in
              </span>
            </>
          ) : (
            <>
              Don't have an account?{" "}
              <span
                className={"mode-switcher"}
                onClick={() => setMode(SignUpFormMode.SIGN_UP)}
              >
                Sign up
              </span>
            </>
          )}
        </Sub>
      )}
    </Form>
  );
}

export default function SignUpForm({
  showHeading = true,
  ...props
}: SignUpFormProps) {
  const { user: signedInUser, loading } = useAuthenticatedUser({
    redirectToLogin: false,
  });

  useEffect(() => {
    if (!loading && signedInUser) {
      props.onComplete();
    }
  }, [loading, signedInUser]);

  let content: React.ReactNode = null;
  if (!loading) {
    if (signedInUser) {
      content = (
        <TextContent tw="mt-8">
          You are in. Now all Startup IQ products and services are available to
          you.
        </TextContent>
      );
    } else {
      content = <SignUpFormComponent {...props} />;
    }
  }

  return (
    <FadeIn duration={300}>
      <TextContent>
        {showHeading && (
          <Heading>
            A <span tw="text-primary-500">Startup IQ</span> account will allow
            you to save your progress and manage your profile
          </Heading>
        )}
        {content}
      </TextContent>
    </FadeIn>
  );
}
